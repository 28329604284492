/* eslint-disable no-unused-vars */
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import Loading from "_components/Loading";
import Typography from "_components/Typography";
import { useDescribeAlarms } from "_libs/swr/dashboard/dashboard";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import styled from "styled-components";

ChartJS.register(ArcElement, Tooltip, Legend);

const IssuesByRiskCard = () => {
  const { t } = useTranslation();
  const {
    data: alarms,
    isLoading: isLoadingAlarms,
    isError: isErrorAlarms,
  }: any = useDescribeAlarms();

  // 위험도별 이슈 수 계산
  const issueCountsBySeverity = (data) => {
    if (!data || isErrorAlarms) {
      return { critical: 0, high: 0, medium: 0, low: 0, normal: 0 };
    }

    return data.reduce(
      (acc, alarm) => {
        acc[alarm.severity] = (acc[alarm.severity] || 0) + 1;
        return acc;
      },
      { critical: 0, high: 0, medium: 0, low: 0, normal: 0 },
    );
  };

  if (isLoadingAlarms) {
    return <Loading.Chart />;
  }

  // Chart data
  const severityCounts = issueCountsBySeverity(alarms?.data || []);

  const totalIssues =
    isErrorAlarms || !alarms
      ? 0
      : alarms?.data.filter((alarm) => alarm.severity !== "normal").length;

  const data = {
    labels: [
      t("dashboard.issuesByRisk.critical"),
      t("dashboard.issuesByRisk.high"),
      t("dashboard.issuesByRisk.medium"),
      t("dashboard.issuesByRisk.low"),
      t("dashboard.issuesByRisk.normal"),
    ],
    datasets: [
      {
        data: [
          severityCounts.critical,
          severityCounts.high,
          severityCounts.medium,
          severityCounts.low,
          severityCounts.normal,
        ],
        backgroundColor: [
          "#FF4D4F",
          "#FFA940",
          "#FFC53D",
          "#A0D911",
          "#caeecd",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <S.Container>
      <S.Title variant="subtitle.200_sb" color="text_5">
        {t("dashboard.issuesByRisk.title")}
      </S.Title>
      <S.Content>
        <S.ChartContainer>
          <Doughnut data={data} options={options} />
          <S.CenterText>
            <S.TotalContainer>
              <S.TotalIssues variant="subtitle.100_sb" color="text_5">
                {totalIssues}
              </S.TotalIssues>
              <S.SubText variant="body.300" color="text_5">
                {t("dashboard.issuesByRisk.allRisk")}
              </S.SubText>
            </S.TotalContainer>
          </S.CenterText>
        </S.ChartContainer>

        <S.LegendContainer>
          {data.labels.map((label, index) => (
            <S.LegendItem key={index}>
              <S.LegendColor color={data.datasets[0].backgroundColor[index]} />
              <S.LegendText variant="body.300" color="text_20">
                {label}
              </S.LegendText>
              <S.LegendValue
                variant="body.300_sb"
                color={label === "Critical" ? "#FF4D4F" : ""}
              >
                {data.datasets[0].data[index]}
              </S.LegendValue>
            </S.LegendItem>
          ))}
        </S.LegendContainer>
      </S.Content>
    </S.Container>
  );
};

export default IssuesByRiskCard;

const S = {
  Container: styled.div({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  Title: styled(Typography)({
    marginBottom: "16px",
  }),
  Content: styled.div({
    display: "flex",
    flex: 1,
  }),
  ChartContainer: styled.div({
    position: "relative",
    width: "50%",
    height: "200px",
  }),
  CenterText: styled.div({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }),
  TotalContainer: styled.div({
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
  TotalIssues: styled(Typography)({}),
  SubText: styled(Typography)({}),
  LegendContainer: styled.div({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "50%",
    paddingLeft: "16px",
  }),
  LegendItem: styled.div({
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  }),
  LegendColor: styled.div<{ color: any }>(({ color }) => ({
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "8px",
  })),
  LegendText: styled(Typography)({
    marginRight: "8px",
    flex: 1,
  }),
  LegendValue: styled(Typography)<{ color: any }>(({ color }) => ({
    marginRight: "8px",
    color: color,
  })),
};
