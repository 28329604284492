"use client";

import { Layout } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import BillingCard from "_components/Dashboard/BillingCard";
import IssueListCard from "_components/Dashboard/IssueListCard";
import IssuesByRiskBox from "_components/Dashboard/IssuesByRiskCard";
import NoticeCard from "_components/Dashboard/NoticeCard";
import ResourceStatusCard from "_components/Dashboard/ResourceStatusCard";
import SolutionContactCard from "_components/Dashboard/SolutionContactCard";
import Tabs from "_components/Dashboard/Tab";
import { Grid } from "_components/Grid";
import { GridLayout } from "_components/Layout/GridLayout";
import styled from "styled-components";

const defaultLayout: Layout[] = [
  { i: "solution-contact", x: 0, y: 0, w: 12, h: 2 },
  { i: "resource-status", x: 0, y: 0, w: 6, h: 3 },
  { i: "issue-list", x: 0, y: 6, w: 6, h: 3 },
  { i: "issues-by-risk", x: 6, y: 6, w: 3, h: 2 },
  { i: "billing", x: 9, y: 0, w: 3, h: 2 },
  { i: "notice", x: 6, y: 6, w: 6, h: 3 },
];

const componentMap = {
  "solution-contact": SolutionContactCard,
  "resource-status": ResourceStatusCard,
  "issue-list": IssueListCard,
  billing: BillingCard,
  "issues-by-risk": IssuesByRiskBox,
  notice: NoticeCard,
  // 다른 컴포넌트들도 여기에 추가하세요
};

export default function Page() {
  const { t } = useTranslation();

  const tabItems = [
    {
      label: t("aws"),
      active: "on" as const,
      onClick: () => {
        /* AWS S3 탭 클릭 처리 */
      },
    },
    {
      label: t("gcp"),
      active: "off" as const,
      onClick: () => {
        /* Google Cloud 탭 클릭 처리 */
      },
    },
    {
      label: t("azure"),
      active: "off" as const,
      onClick: () => {
        /* Azure 탭 클릭 처리 */
      },
    },
    {
      label: t("oracle"),
      active: "off" as const,
      onClick: () => {
        /* Oracle 탭 클릭 처리 */
      },
    },
  ];

  return (
    <S.Container>
      <Tabs items={tabItems} />
      <GridLayout defaultLayout={defaultLayout}>
        {defaultLayout.map((item) => {
          const Component = componentMap[item.i] || S.DefaultBox;
          return (
            <S.GridLayoutBox
              key={item.i}
              $isSolutionContact={item.i === "solution-contact"}
            >
              {/* 임시 드래그 핸들 작업 */}
              <S.DraggableHandle
                className="draggable-handle"
                type="button"
                aria-label="이동"
              />
              <Component />
            </S.GridLayoutBox>
          );
        })}
      </GridLayout>
    </S.Container>
  );
}

const S = {
  GridLayoutBox: styled("div")<{ $isSolutionContact: boolean }>`
    background-color: ${(props) =>
      props.$isSolutionContact ? "#2c2c2c" : "var(--white)"};
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: ${(props) =>
      props.$isSolutionContact ? "none" : "0px 0px 5px 0px rgba(0, 0, 0, 0.1)"};
    padding: ${(props) => (props.$isSolutionContact ? "0" : "20px")};
    box-sizing: border-box;
    position: relative;
  `,
  Container: styled(Grid.Item)({
    width: "100%",
    height: "fit-content",
    backgroundColor: "var(--background_default2)",
  }),
  DefaultBox: styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: "#666",
  }),
  DraggableHandle: styled("button")({
    position: "absolute",
    top: "5px",
    left: "5px",
    width: "16px",
    height: "16px",
    cursor: "grab",
    zIndex: 1,
    border: "none",
    outline: "none",
    padding: "2px",
    background: "transparent",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    "&::before, &::after": {
      content: '""',
      width: "100%",
      height: "2px",
      background: "var(--gray_300)",
      borderRadius: "1px",
    },
    "&:hover": {
      "&::before, &::after": {
        background: "var(--gray_300)",
      },
    },
    "&:active": {
      cursor: "grabbing",
    },
  }),
};
