import React from "react";
import { useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./GridLayout.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface CustomGridLayoutProps {
  defaultLayout: Layout[];
  children: React.ReactNode;
}

/**
 * 
 * @example
const defaultLayout: Layout[] = [
  { i: "a", x: 0, y: 0, w: 12, h: 2 },
  { i: "b", x: 0, y: 4, w: 6, h: 3 },
  { i: "c", x: 6, y: 4, w: 6, h: 3 },
  { i: "d", x: 0, y: 6, w: 3, h: 2 },
  { i: "e", x: 3, y: 6, w: 3, h: 2 },
];

<GridLayout defaultLayout={defaultLayout}>
  {defaultLayout.map((item) => (
    <div
      key={item.i}
      style={{
        backgroundColor: "#f0f0f0",
        transition: "all 0.3s",
        border: "1px solid #ddd",
        boxSizing: "border-box",
      }}
    >
      Content for {item.i.toUpperCase()}
    </div>
  ))}
</GridLayout>
 */

export function GridLayout({ defaultLayout, children }: CustomGridLayoutProps) {
  const [currentLayout, setCurrentLayout] = useState<Layout[]>(() => {
    if (typeof window !== "undefined") {
      const savedLayout = localStorage.getItem("gridLayout");
      return savedLayout ? JSON.parse(savedLayout) : defaultLayout;
    }
    return defaultLayout;
  });

  const onLayoutChange = (layout: Layout[]) => {
    console.log("layout", layout);
    setCurrentLayout(layout);
    if (typeof window !== "undefined") {
      // localStorage.setItem("gridLayout", JSON.stringify(layout));
    }
  };

  const generateResponsiveLayout = (
    layout: Layout[],
    cols: number,
  ): Layout[] => {
    return layout.map((item) => ({
      ...item,
      w: Math.min(item.w, cols),
      x: Math.min(item.x, cols - 1),
    }));
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={{
        lg: currentLayout,
        md: generateResponsiveLayout(currentLayout, 10),
        sm: generateResponsiveLayout(currentLayout, 6),
        xs: generateResponsiveLayout(currentLayout, 4),
        xxs: generateResponsiveLayout(currentLayout, 2),
      }}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={120}
      margin={[20, 20]}
      onLayoutChange={(layout, layouts) => onLayoutChange(layouts.lg)}
      isDraggable={true}
      isResizable={true}
      compactType="vertical"
      useCSSTransforms={false} // 애니메이션 효과 제거
      draggableHandle=".draggable-handle"
    >
      {children}
    </ResponsiveGridLayout>
  );
}
