import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "_components/Typography";
import styled from "styled-components";

interface NoticeItem {
  id: number;
  title: string;
  date: string;
  isNew?: boolean;
}

// TODO: 추후 라우터 작업 필요함
const NoticeCard: React.FC = () => {
  const { t } = useTranslation();
  const noticeItems: NoticeItem[] = [
    {
      id: 1,
      title: "[공지] 모니터링 서버 업데이트 (완료)",
      date: "24.10.15",
      isNew: true,
    },
    { id: 2, title: "[공지] 시스템 정기점검 공지", date: "24.09.30" },
    { id: 3, title: "[안내] 커스텀 서버 접근 안내", date: "24.06.06" },
    {
      id: 4,
      title: "[안내] 시스템 업그레이드로 인한 서비스 일시 중지 안내",
      date: "24.03.24",
    },
    { id: 5, title: "[안내] 1.3.0 업데이트 안내", date: "24.03.14" },
    { id: 6, title: "[안내] 사용자를 위한 연결가이드", date: "24.02.12" },
  ];

  return (
    <S.Container>
      <S.Header>
        <S.Title variant="subtitle.200_sb" color="text_5">
          {t("dashboard.notice.title")}
        </S.Title>
        <S.ViewAllButton>
          <Typography variant="caption.100" color="text_15">
            {t("dashboard.notice.viewAll")}
          </Typography>
        </S.ViewAllButton>
      </S.Header>
      <S.NoticeList>
        {noticeItems.map((item) => (
          <S.NoticeItem key={item.id}>
            <S.NoticeTitle variant="body.300" color="text_10">
              {item.title}
              {item.isNew && <S.NewBadge>N</S.NewBadge>}
            </S.NoticeTitle>
            <S.NoticeDate variant="body.300" color="text_30">
              {item.date}
            </S.NoticeDate>
          </S.NoticeItem>
        ))}
      </S.NoticeList>
    </S.Container>
  );
};

const S = {
  Container: styled("div")({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  Header: styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  }),
  Title: styled(Typography)({}),
  ViewAllButton: styled("button")({
    background: "none",
    border: "none",
    color: "#007bff",
    cursor: "pointer",
  }),
  NoticeList: styled("ul")({
    listStyle: "none",
    padding: 0,
    margin: 0,
    flex: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E0E0E0",
      borderRadius: "3px",
    },
  }),
  NoticeItem: styled("li")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    padding: "20px 8px",
    borderBottom: "1px solid var(--line_10)",
    "&:last-child": {
      borderBottom: "none",
    },
  }),
  NoticeTitle: styled(Typography)({
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  NewBadge: styled("span")({
    backgroundColor: "var(--red-500)",
    color: "white",
    fontSize: "10px",
    padding: "2px 4px",
    borderRadius: "4px",
    marginLeft: "8px",
  }),
  NoticeDate: styled(Typography)({
    whiteSpace: "nowrap",
    marginRight: "10px",
  }),
};

export default NoticeCard;
