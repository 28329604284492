import { AnyStyledComponent } from "styled-components";

import { useApi } from "../swr-instance";

export function getMyUser(params?: AnyStyledComponent) {
  return useApi({
    apiKey: "MAIN",
    path: "/user/my-info",
    params,
    swrConfigs: {
      onError: (error, key) => {
        console.error("SWR Error:", error, "for key:", key);
      },
      revalidateOnFocus: false, // 포커스 시 재검증 비활성화
      revalidateOnReconnect: false, // 재연결 시 재검증 비활성화
      refreshInterval: 0, // 주기적 재검증 비활성화
      shouldRetryOnError: false, // 에러 발생 시 재시도 비활성화
    },
  });
}
