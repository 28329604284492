/* eslint-disable no-unused-vars */
import { useTranslation } from "react-i18next";
import { Button } from "_components/Button";
import Loading from "_components/Loading";
import Typography from "_components/Typography";
import { useDescribeAlarms } from "_libs/swr/dashboard/dashboard";
import { useRouter } from "next/navigation";
import styled from "styled-components";

const IssueListCard = () => {
  const router = useRouter();

  const { t } = useTranslation();

  const {
    // data: alarms,
    mutate: mutateAlarms,
    isLoading: isLoadingAlarms,
    isError: isErrorAlarms,
  }: any = useDescribeAlarms();

  if (isLoadingAlarms) {
    return <Loading.Chart />;
  }

  const alarms: any = [{ data: [] }];

  return (
    <S.Container>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <S.Title variant="subtitle.200_sb" color="text_5">
          {t("dashboard.issueLogs.title")}
        </S.Title>

        {alarms.data?.length > 0 && (
          <Button
            variant="grayline"
            size="xs"
            style={{ width: "90px" }}
            onClick={() => {
              router.push("/watch");
            }}
          >
            {t("dashboard.issueLogs.createdAlarm")}
          </Button>
        )}
      </div>

      {!alarms.data || alarms.data.length === 0 ? (
        <S.EmptyContainer>
          {/* <SVG src={Report} width={60} height={60} stroke="var(--Bgray_400)" /> */}
          <S.EmptyMessage variant="body.300" color="text_20">
            {t("dashboard.issueLogs.noIssueLogs")}
          </S.EmptyMessage>
          <Button
            variant="grayline"
            size="xs"
            onClick={() => router.push("/watch")}
          >
            {t("dashboard.issueLogs.setAlarm")}
          </Button>
        </S.EmptyContainer>
      ) : (
        <S.IssueList>
          {alarms.data.map((issue, index) => {
            console.log("issue", issue);
            return (
              <S.IssueItem key={index} severity={issue.severity}>
                <S.IssueTitle variant="body.300" color="text_5">
                  {issue.alarmName}
                </S.IssueTitle>
                <S.IssueDescription variant="caption.200" color="text_20">
                  {issue.alarmDescription ||
                    t("dashboard.issueLogs.noDescription")}
                </S.IssueDescription>
              </S.IssueItem>
            );
          })}
        </S.IssueList>
      )}
    </S.Container>
  );
};

export default IssueListCard;

const S = {
  Message: styled(Typography)({
    textAlign: "center",
    padding: "20px",
  }),

  Container: styled("div")({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  Title: styled(Typography)({
    marginBottom: "16px",
  }),
  IssueList: styled("div")({
    overflowY: "auto",
    flexGrow: 1,

    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E0E0E0",
      borderRadius: "3px",
    },
  }),
  IssueItem: styled("div")<{ severity: string }>(({ severity }) => ({
    backgroundColor: severity === "critical" ? "wthie" : "wthie",
    borderRadius: "4px",
    marginBottom: "16px",
    position: "relative",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    height: "38px",

    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      width: "2px",
      backgroundColor:
        severity === "high" || severity === "critical"
          ? "#FF0000" // 빨간색
          : severity === "medium"
            ? "#FFA000" // 오렌지색
            : severity === "low"
              ? "#2196F3"
              : "#FF0000", // 우선은 전체 빨간색으로 표시
    },
  })),
  IssueTitle: styled(Typography)({
    marginBottom: "4px",
  }),
  IssueDescription: styled(Typography)({}),

  EmptyContainer: styled.div({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    flexGrow: 1,
  }),
  EmptyMessage: styled(Typography)({
    textAlign: "center",
  }),
};
