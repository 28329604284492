import { useTranslation } from "react-i18next";
import Loading from "_components/Loading";
import Typography from "_components/Typography";
import { getCostAndUsage } from "_libs/swr/dashboard/dashboard";
import { getMyUser } from "_libs/swr/user/user";
import styled from "styled-components";

const BillingCard = () => {
  const { t } = useTranslation();
  // 이번 달의 첫날과 마지막 날 계산
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // 날짜 형식을 'YYYY-MM-DD'로 변환하는 함수
  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const { data: user }: any = getMyUser();
  const { data: costAndUsage, isLoading: isLoadingCostAndUsage }: any =
    getCostAndUsage(
      formatDate(firstDayOfMonth),
      formatDate(lastDayOfMonth),
      user.id,
    );

  // 아래 사이클 코드는 추후 변경 필요
  // 데이터 로딩 중일 때 처리
  if (isLoadingCostAndUsage) {
    return <Loading.Chart />;
  }

  // 누적 비용
  const accrueCost = costAndUsage?.accrueCost || 0;

  // 현재 기간의 비용 (가장 최근 데이터)
  const currentPeriodCost =
    (costAndUsage?.results &&
      costAndUsage?.results[0]?.ResultsByTime.slice(-1)[0]?.Total?.UnblendedCost
        .Amount) ||
    "0";

  // 이전 달 비용 (동적으로 계산)
  const currentDate = new Date();
  const previousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1,
  );
  const previousMonthString = previousMonth.toISOString().slice(0, 7); // YYYY-MM 형식

  const previousMonthCost =
    (costAndUsage?.results &&
      costAndUsage?.results[0].ResultsByTime.find((item) =>
        item.TimePeriod.Start.startsWith(previousMonthString),
      )?.Total.UnblendedCost.Amount) ||
    "0";

  // 전일 비용 (현재 기간의 바로 이전 데이터)
  const previousDayCost =
    (costAndUsage?.results &&
      costAndUsage?.results[0].ResultsByTime.slice(-2)[0].Total.UnblendedCost
        .Amount) ||
    "0";

  return (
    <S.Container>
      <S.Title variant="subtitle.200_sb" color="text_5">
        {t("dashboard.billing.title")}
      </S.Title>
      <S.CurrentPeriod>
        <S.PeriodInfo>
          <S.PeriodTitle variant="body.300_sb" color="white">
            {t("dashboard.billing.monthToDateCost")}
          </S.PeriodTitle>
          <S.PeriodDate variant="caption.200" color="white">
            {costAndUsage?.results &&
              costAndUsage?.results[0].ResultsByTime[0].TimePeriod.Start}{" "}
            ~ {t("dashboard.billing.today")}
          </S.PeriodDate>
        </S.PeriodInfo>
        <S.Amount>${parseFloat(accrueCost).toLocaleString()}</S.Amount>
      </S.CurrentPeriod>
      <S.BillingList>
        <S.BillingItem>
          <S.ItemTitle variant="body.300" color="text_10">
            {t("dashboard.billing.estimatedCost")}
          </S.ItemTitle>
          <S.ItemAmount variant="body.300" color="text_10">
            ${parseFloat(currentPeriodCost).toLocaleString()}
          </S.ItemAmount>
        </S.BillingItem>
        <S.Divider />
        <S.BillingItem>
          <S.ItemTitle variant="body.300" color="text_10">
            {t("dashboard.billing.previousDayCost")}
          </S.ItemTitle>
          <S.ItemAmount variant="body.300" color="text_10">
            ${parseFloat(previousDayCost).toLocaleString()}
          </S.ItemAmount>
        </S.BillingItem>
        <S.Divider />
        <S.BillingItem>
          <S.ItemTitle variant="body.300" color="text_10">
            {t("dashboard.billing.previousMonthCost")}
          </S.ItemTitle>
          <S.ItemAmount variant="body.300" color="text_10">
            ${parseFloat(previousMonthCost).toLocaleString()}
          </S.ItemAmount>
        </S.BillingItem>
      </S.BillingList>
    </S.Container>
  );
};

export default BillingCard;

const S = {
  Container: styled("div")({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  Title: styled(Typography)({
    marginBottom: "14px",
  }),
  CurrentPeriod: styled("div")({
    backgroundColor: "#333",
    color: "white",
    padding: "16px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  PeriodInfo: styled("div")({
    display: "flex",
    flexDirection: "column",
  }),
  PeriodTitle: styled(Typography)({
    marginBottom: "4px",
  }),
  PeriodDate: styled(Typography)({}),
  Amount: styled("p")({
    fontSize: "24px",
    fontWeight: 600,
  }),
  BillingList: styled("div")({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "0px 20px 0px 20px",
  }),
  BillingItem: styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
  }),
  ItemTitle: styled(Typography)({}),
  ItemAmount: styled(Typography)({}),
  Divider: styled("div")({
    height: "1px",
    backgroundColor: "#E0E0E0",
  }),
};
