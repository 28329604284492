import { DragEvent, FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ic_document from "_assets/icons/ic_document.svg";
import { Button } from "_components/Button";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";
import styled from "styled-components";

const SolutionContactCard: FC = () => {
  const { t, i18n } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <S.Container>
      <Typography variant="title.100_sb" color="white">
        {t("dashboard.solution.title")}
        <br />
        {t("dashboard.solution.subtitle")}
      </Typography>

      <S.RightSection>
        <div />

        <S.UploadBox
          onClick={handleUploadClick}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {!file && (
            <SVG
              src={ic_document}
              width={24}
              height={24}
              stroke="var(--text_30)"
            />
          )}

          <Typography variant="body.200" color="text_30">
            {file ? file.name : t("dashboard.solution.upload")}
          </Typography>
        </S.UploadBox>

        <Button
          variant="default"
          size="sm"
          disabled={!file}
          width="200px"
          style={{ marginBottom: "16px" }}
          onClick={() => {
            // 여기에 파일 제출 로직을 추가하세요
            console.log("파일 제출:", file);
          }}
        >
          {t("dashboard.solution.button")}
        </Button>
      </S.RightSection>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    background-color: #2c2c2c;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
  `,
  RightSection: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
  `,
  UploadBox: styled.div`
    border: 2px dashed #666;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 820px;
    height: 90px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    gap: 8px;

    @media (max-width: 1200px) {
      width: 500px;
    }
    @media (max-width: 768px) {
      width: 300px;
    }
    @media (max-width: 480px) {
      width: 200px;
    }
  `,
};

export default SolutionContactCard;
